import React from "react";
import ProductList from "../components/ProductList";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Homepage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className=" mt-10">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          axis="vertical"
          dynamicHeight={true}
          interval={5000}
          swipeable={false}
        >
          <div>
            <img
              src="/assets/img/r7150-image1.jpg"
              alt="r7150"
              className="h-screen md:h-[90vh] object-none md:object-cover"
            />
            <div className="max-w-[1140px] m-auto">
              <div className="absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4 bg-gray-800/80">
                <h2 className="text-3xl text-center md:text-left drop-shadow-sm">
                  R7150
                </h2>
                <h1 className="uppercase text-4xl sm:text-6xl  text-center md:text-left  text-[#6abf4b] mb-4">
                  {t("homelift")}
                </h1>
                <p className="text-4xl  text-center md:text-left drop-shadow-sm">
                  {t("r7150slogan")}
                </p>
              </div>
            </div>
          </div>

          <div>
            <img
              src="/assets/img/m6200-image1.jpg"
              alt="M6200"
              className="h-screen md:h-[90vh] object-none md:object-cover"
            />
            <div className="max-w-[1140px] m-auto">
              <div className="absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4 bg-gray-800/80">
                <h2 className="text-3xl text-center md:text-left drop-shadow-sm">
                  M6200
                </h2>
                <h1 className="uppercase text-4xl sm:text-6xl text-center md:text-left text-[#6abf4b] mb-4">
                  {t("passengerlift")}
                </h1>
                <p className="text-4xl text-center md:text-left drop-shadow-sm">
                  {t("m6200slogan")}
                </p>
              </div>
            </div>
          </div>

          <div>
            <img
              src="/assets/img/a2100-image1.jpg"
              alt="a2100"
              className="h-screen md:h-[90vh] object-none md:object-cover"
            />
            <div className="max-w-[1140px] m-auto">
              <div className="absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4 bg-gray-800/80">
                <h2 className="text-3xl text-center md:text-left drop-shadow-sm">A2100</h2>
                <h1 className="uppercase text-4xl text-center md:text-left sm:text-6xl text-[#6abf4b] mb-4">
                  {t("stretcherlift")}
                </h1>
                <p className="text-4xl text-center md:text-left drop-shadow-sm">{t("a2100slogan")}</p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>

      <div className="h-24 w-px bg-lime-800 m-auto"></div>
      <div className="max-w-[1140px] m-auto py-4 px-4 dark:text-white">
        <h1 className="font-bold text-2xl text-center mb-4 text-[#6abf4b]">
          {t("aboutus")}
        </h1>
        <p className="text-justify mb-4">{t("aboutusp1")}</p>
        <p className="text-justify mb-4">{t("aboutusp2")}</p>
        <p>{t("aboutusp3")}</p>
        <div className="text-center mt-4">
          <Link to="/corporate">
            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">
              {t("continue")}
            </button>
          </Link>
        </div>
      </div>
      <div className="h-24 w-px bg-lime-800 m-auto"></div>
      <div className="mt-4">
        <h1 className="uppercase font-bold text-2xl text-center mb-4 text-[#6abf4b]">
          {t("products")}
        </h1>
        <ProductList />
      </div>
      <div className="h-24 w-px bg-lime-800 m-auto"></div>
      <div className="max-w-[1140px] m-auto py-4 px-4 dark:text-white">
        <h1 className="uppercase font-bold text-2xl text-center mb-4 text-[#6abf4b]">
          {t("services")}
        </h1>
        <div className="text-center p-5">
          <img
            src="/assets/img/assembly.svg"
            alt="assembly"
            width={50}
            className="object-center m-auto"
          />
          <h2 className="uppercase text-xl mb-2">{t("assembly")}</h2>
          <p className="text-justify">{t("assemblyp1")}</p>
        </div>
        <div className="text-center p-5">
          <img
            src="/assets/img/renewal.svg"
            alt="assembly"
            width={50}
            className="object-center m-auto"
          />
          <h2 className="uppercase text-xl mb-2">{t("modernization")}</h2>
          <p className="text-justify">{t("modernizationp1")}</p>
        </div>
      </div>
      <div className="h-24 w-px bg-lime-800 m-auto"></div>
    </>
  );
};

export default Homepage;
