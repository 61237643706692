import React from 'react';
import { useTranslation } from 'react-i18next';

const DumbwaiterS7100 = () => {
  const { t } = useTranslation();
  return (
    <>
    <div className='w-full h-[90vh]'>
      <img
          src="/assets/img/s7100-image1.jpg"
          alt="S7100"
          className="w-full h-full object-cover"
          />
          <div className='max-w-[1140px] m-auto'>
            <div className='absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4'>
              <h2 className='text-3xl drop-shadow-sm'>S7100</h2>
              <h1 className='uppercase text-4xl sm:text-6xl text-[#6abf4b] mb-4'>{t('dumbwaiter')}</h1>
              <p className='text-4xl drop-shadow-sm'>{t('s7100slogan')}</p>
            </div>
          </div>
    </div>
    <div className='max-w-[1140px] mx-auto my-10 p-4 dark:text-white'>
      <div>
        <h2 className='text-center text-xl'>S7100</h2>
        <h1 className='uppercase text-center text-4xl mb-8 text-[#6abf4b]'>{t('dumbwaiter')}</h1>
      </div>
      <div className='mx-auto mb-8'>
        <p1 className='text-4xl'>{t('s7100p1')}</p1>
        <p className='mt-4'>{t('s7100p2')}</p>
      </div>

      <div className='grid md:grid-cols-2 gap-4 mb-8'>
        <div>
          <h2 className='font-bold text-xl mb-4'>{t('s7100t1')}</h2>
          <p>{t('s7100p3')}</p>
        </div>
        <div>
          <h2 className='font-bold text-xl mb-4'>{t('s7100t2')}</h2>
          <p className='dark:text-white'>{t('s7100p4')}</p>
        </div>
      </div>
    </div>

   
      <div className='h-[500px] bg-no-repeat bg-cover bg-center bg-fixed border-y border-lime-800' style={{backgroundImage: "url(/assets/img/s7100-image2.jpg"}}>
      </div>

      <div className="max-w-[1140px] px-4 py-10 mx-auto dark:text-white">
      <div>
          <h1 className="uppercase text-center sm:text-2xl text-2xl font-medium title-font mb-4">
            {t("techspec")}
          </h1>
        <table className='w-full'>
            <tr className='border-y border-gray-500'>
              <td>{t('capacity')}</td>
              <td>50 ~ 250 kg</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('msq')}</td>
              <td>8</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('mtd')}</td>
              <td>25 m</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('machine')}</td>
              <td>{t('machinetd')}</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('construction')}</td>
              <td>{t('constructiontd')}</td>
            </tr>
        </table>

        <h1 className="uppercase text-center text-xl font-medium title-font mt-4">
            {t("cabin")}
          </h1>
        <table className='w-full text-center'>
            <tr className='border-y border-gray-500'>
              <td>{t('hairline')}</td>
              <td>{t('rms')}</td>
              <td>{t('sde')}</td>
            </tr>
        </table>

        <h1 className="uppercase text-center text-xl font-medium title-font mt-4">
            {t('landingdoors')}
          </h1>
        <table className='w-full text-center'>
            <tr className='border-y border-gray-500'>
              <td className='uppercase'>{t('guillotinedoor')}</td>
              <td className='uppercase'>{t('swingdoor')}</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('hairline')}</td>
              <td>{t('hairline')}</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('ceo')}: 500-1000mm</td>
              <td>{t('ceo')}: 600-1000mm</td>
            </tr>
            <tr className='border-y border-gray-500'>
              <td>{t('ceh')}: 500-1000mm</td>
              <td>{t('ceh')}: 1000-1200mm</td>
            </tr>
        </table>
      </div>
    </div>

    </>
  )
}

export default DumbwaiterS7100