import React from 'react'
import { FaPhone } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const TopBar = () => {

const { i18n } = useTranslation();

const clickHandle = lang => {
    i18n.changeLanguage(lang)
}

  return (
    <div className='bg-gray-200 dark:bg-gray-900 dark:text-white'>
        <div className='max-w-[1140px] m-auto '>
            <div className='flex justify-end items-center px-4 py-2'>
                <div className='hidden md:flex items-center px-2'>
                    <FaEnvelope className="mr-2"/>
                    <p>info.it@marselevator.com</p>
                </div>
                <div className='hidden md:flex items-center px-2'>
                    <FaPhone className="mr-2"/>
                    <p>+39 028 082 90 75</p>
                </div>
                <div className='md:flex items-center px-2'>
                    <button onClick={() => clickHandle('it')}>IT</button>
                </div>
                <div className='md:flex items-center px-2'>
                    <button onClick={() => clickHandle('en')}>EN</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopBar