import React from 'react'

const Projects = () => {
  return (
    <>
    <div className='w-full'>
      <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
          <h1 className='mt-6 text-3xl text-[#6abf4b]'>PROJECTS</h1>
      </div>
    </div>
    <div className='m-auto'>
      <div className='grid h-[80vh] place-items-center'>
        <img
          src='/assets/img/marsmaintenanceimage.png'
          alt='Maintenance'
        />
      </div>
    </div>
    </>
  )
}

export default Projects