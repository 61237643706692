import React from 'react'

const ItalianFlag = () => {
  return (
    <>
        <div className='relative w-96 m-auto'>
            <div className='flex justify-center fixed bottom-0'>
                <div className='w-32 h-2 bg-[#008c45]'></div>
                <div className='w-32 h-2 bg-[#f4f5f0]'></div>
                <div className='w-32 h-2 bg-[#cd212a]'></div>
            </div>
        </div>
    </>
  )
}

export default ItalianFlag