import React from 'react'
import { useTranslation } from 'react-i18next';

const Career = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full'>
      <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' 
           style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
          <h1 className='uppercase mt-6 text-3xl text-[#6abf4b]'>{t('career')}</h1>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
        <div className='max-w-[1140px] m-auto py-4 px-4 dark:text-white'>
            <h1 className='text-center text-2xl uppercase'>{t('humanresources')}</h1>
            <div className='text-center p-5'>
                <h2 className='uppercase text-xl mb-2'>{t('hrpolicy')}</h2>
                <p className='text-justify mb-4'>{t('hrpolicyp1')}</p>
                <p className='text-justify'>{t('hrpolicyp2')}</p>
            </div>
            <div className='h-24 w-px bg-lime-800 m-auto'></div>
            <div className='text-center p-5'>
                <h2 className='uppercase text-xl mb-2'>{t('recruitmentprocess')}</h2>
                <p className='text-justify'>{t('recruitmentprocessp')}</p>
            </div>
            <div className='flex flex-wrap m-auto text-center'>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>1</h1>
                  </div>
                  <p>{t('recruitmentprocessp1')}</p>
                </div>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>2</h1>
                  </div>
                  <p>{t('recruitmentprocessp2')}</p>
                </div>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>3</h1>
                  </div>
                  <p>{t('recruitmentprocessp3')}</p>
                </div>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>4</h1>
                  </div>
                  <p>{t('recruitmentprocessp4')}</p>
                </div>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>5</h1>
                  </div>
                  <p>{t('recruitmentprocessp5')}</p>
                </div>
                <div className='max-w-[180px] p-4'>
                  <div className='flex items-center justify-center m-auto mb-4 bg-[#6abf4b] rounded-3xl w-10 h-10'>
                      <h1 className='font-bold'>6</h1>
                  </div>
                  <p>{t('recruitmentprocessp6')}</p>
                </div>
            </div>
        </div>
        <div className='h-24 w-px bg-lime-800 m-auto'></div>
    </div>
  )
}

export default Career