import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <div className="w-full">
        <div
          className="h-56 bg-repeat grid place-items-center border-b border-lime-800"
          style={{ backgroundImage: "url(/assets/img/pattern_01.jpg" }}
        >
          <h1 className="mt-6 text-3xl text-[#6abf4b]">CONTACT</h1>
        </div>
      </div>

        <div className="max-w-[1140px] m-auto px-6 py-12 mx-auto">
          <div>
            <h1 className="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">
              Get in touch
            </h1>

            <p className="mt-3 text-gray-500 dark:text-gray-400">
              Our friendly team would love to hear from you.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-1">
              <div>
                <span className="inline-block p-3 text-[#6abf4b] rounded-full bg-blue-100/80 dark:bg-gray-800">
                  <FaEnvelope />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-500 dark:text-gray-400">
                  Email
                </h2>
                <p className="mt-2 dark:text-white">info.it@marselevator.com</p>
              </div>

              <div>
                <span className="inline-block p-3 text-[#6abf4b] rounded-full bg-blue-100/80 dark:bg-gray-800">
                  <FaMapMarkerAlt />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-500 dark:text-gray-400">
                  Office
                </h2>
                <p className="mt-2 dark:text-white">
                  Via Della Pace San Guiliano n.10C 20098 Milanese (MI)/ITALIA
                </p>
              </div>

              <div>
                <span className="inline-block p-3 text-[#6abf4b] rounded-full bg-blue-100/80 dark:bg-gray-800">
                  <FaPhoneAlt />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-500 dark:text-gray-400">
                  Phone
                </h2>
                <p className="mt-2 dark:text-white">+39 028 082 90 75</p>
              </div>
            </div>

            <div className="overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                title="map"
                marginheight="0"
                marginwidth="0"
                scrolling="no"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2802.339514358112!2d9.304867477378423!3d45.382321638896435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1689078475695!5m2!1str!2str" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

      <div className="max-w-[1140px] m-auto p-4 py-6 my-8 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
                <form>
                    <div className="-mx-2 md:items-center md:flex">
                        <div className="flex-1 px-2">
                            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">First Name</label>
                            <input type="text" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div className="flex-1 px-2 mt-4 md:mt-0">
                            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Last Name</label>
                            <input type="text" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>
                    </div>

                    <div className="mt-4">
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                        <input type="email" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>

                    <div className="w-full mt-4">
                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                        <textarea className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"></textarea>
                    </div>

                    <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                        Send message
                    </button>
                </form>
            </div>
    </>
  );
};

export default Contact;
