import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Products = () => {
    const { t } = useTranslation();
  return (
    <div className='w-full'>
        <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
            <h1 className='uppercase mt-6 text-3xl text-[#6abf4b]'>{t('products')}</h1>
        </div>
        <div className='flex flex-wrap justify-center m-auto my-6'>
            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-m6100.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('passengerlift')} - M6100</h1>
                </div>
                <div  className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('m6100p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/PassengerLiftM6100'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-m6200.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('passengerlift')} - M6200</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('m6200p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/PassengerLiftM6200'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2100.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('stretcherlift')} - A2100</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2100p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/StretcherLiftA2100'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2200.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('freightlift')} - A2200</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2200p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/FreightLiftA2200'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2300.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('emergencylift')} - A2300</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2300p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/EmergencyLiftA2300'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2400.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('antivandal')} - A2400</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2400p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/AntivandalA2400'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-r7150.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('homelift')} - R7150</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('r7150p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/HomeliftR7150'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-r7350.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('modernization')} - R7350</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('r7350p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/ModernizationR7350'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-s7100.jpg" alt="avatar"/>
                <div className="flex items-center px-4 py-2 bg-gray-900">
                    <h1 className="uppercase text-sm text-white font-semibold">{t('dumbwaiter')} - S7100</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-48 py-2 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('s7100p1')}</p>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='/products/DumbwaiterS7100'>
                            <button className="uppercase bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

    </div>
</div>
  )
}

export default Products