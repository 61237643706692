import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaFileAlt } from "react-icons/fa";

const Catalog = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get('../data/catalog.json');
        setData(response.data);
      }
      fetchData();
    })
  return (
      <>
      <div className="max-w-[1140px] px-4 mx-auto dark:text-white">
      <h1 className="uppercase text-xl font-medium title-font mb-4"><FaFileAlt className='w-5 h-5 mr-2 inline-block text-[#cd212a]'/>{t('catalog')}</h1>
      <div className="flex flex-wrap -m-4 text-center">
      {data.map(docs =>
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full" key={docs.id}>
          <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-4 rounded-lg">
            <h2 className="uppercase text-md">{docs.docname}</h2>
            <p className="leading-relaxed mb-4 text-gray-400">{docs.doclang}</p>
            <a href={docs.doclinkname} target="_blank" rel="noreferrer">
            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white px-8 rounded uppercase">{t('download')}</button>
            {docs.doclink}</a>
          </div>
        </div>
      )}
  
      </div>
    </div>
    </>
  )
}

export default Catalog