import React, {Fragment, useEffect, useState} from 'react';
import {  FaBars } from 'react-icons/fa';
import Toggle from "./Toggle";
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const [pos, setPos] = useState(false)
  const [posy, setPosy] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  useEffect(() => {
    const changePosition = () => {
      if (window.scrollY >= 90){
        setPos('fixed');
        setPosy('0px');
      } else {
        setPos('absolute');
        setPosy('40px')
      }
    };
    window.addEventListener('scroll', changePosition);
  }, []);

  const { t } = useTranslation();

  return (
    <div 
    style={{ position: `${pos}`, top: `${posy}`}}
    className='w-full min-h-[50px] bg-gray-800 text-white z-10 ease-in duration-300'>
    <div className='max-w-[1140px] m-auto flex justify-between items-center'>
      <div>
        <img
          src="/assets/img/marselevatorsrl-logo.png"
          alt="Mars Elevator"
          srcset=""
          className="p-2"/>
      </div>
      <ul className='hidden sm:flex px-2'>
        <li>
          <a href='/'>{t("homepage")}</a>
        </li>
        <li>
          <Link to='corporate'>{t("corporate")}</Link>
        </li>
        <li>
          <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              {t("products")}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-600 rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/PassengerLiftM6100'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("passengerlift")} - M6100
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/PassengerLiftM6200'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("passengerlift")} - M6200
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/StretcherLiftA2100'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("stretcherlift")} - A2100
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/FreightLiftA2200'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("freightlift")} - A2200
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/EmergencyLiftA2300'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("emergencylift")} - A2300
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/AntivandalA2400'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("antivandal")} - A2400
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/HomeliftR7150'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("homelift")} - R7150
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/ModernizationR7350'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("modernization")} - R7350
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link to='/products/DumbwaiterS7100'
                      className={`${
                        active ? 'bg-[#6abf4b] text-white' : 'text-gray-100'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {t("dumbwaiter")} - S7100
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        </li>
        <li>
          <Link to='services'>{t("services")}</Link>
        </li>
        <li>
          <Link to='projects'>{t("projects")}</Link>
        </li>
        <li>
          <Link to='documents'>{t("documents")}</Link>
        </li>
        <li>
          <Link to='career'>{t("career")}</Link>
        </li>
        <li>
          <Link to='contact'>{t("contact")}</Link>
        </li>
        <Toggle />
      </ul>
      {/*Hamburger Menu*/}
      <div onClick={handleNav} className='sm:hidden z-10'>
        <FaBars size={20} className='mr-4 cursor-pointer'/>
      </div>
      {/*Hamburger Menu*/}
      <div 
        onClick={handleNav} 
        className={nav ? 'overflow-y-hidden md:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 w-full h-screen bg-black/70 px-4 py-7 flex flex-col' : 'absolute top-0 h-screen left-[-100%] ease-in duration-300'}>
      <ul className='h-full w-full text-center pt-12'>
        <li className='text-2xl py-4'>
          <a href='/'>Homepage</a>
        </li>
        <li className='text-2xl py-4'>
          <Link to='corporate'>{t("corporate")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='products'>{t("products")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='services'>{t("services")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='projects'>{t("projects")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='documents'>{t("documents")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='career'>{t("career")}</Link>
        </li>
        <li className='text-2xl py-4'>
          <Link to='contact'>{t("contact")}</Link>
        </li>
        <Toggle />
      </ul>
      </div>
    </div>
  </div>
  )
}

export default Navbar