import React from 'react';
import { useTranslation } from 'react-i18next';

const Corporate = () => {
  const { t } = useTranslation();
  return (
    <div className='w-full'>
      <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
          <h1 className='uppercase mt-6 text-3xl text-[#6abf4b]'>{t('corporate')}</h1>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
      <div className='max-w-[1140px] m-auto px-4 mb-8'>
        <h1 className='font-bold text-2xl text-center mb-4 text-[#6abf4b] mt-4'>{t('aboutus')}</h1>
        <p className='dark:text-white mb-3 text-justify'>{t('aboutusp1')}</p>
        <p className='dark:text-white mb-3 text-justify'>{t('aboutusp2')}</p>
        <p className='dark:text-white text-justify'>{t('aboutusp3')}</p>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
      <div className='h-96 bg-no-repeat bg-cover bg-center bg-fixed border-y border-lime-800' style={{backgroundImage: "url(/assets/img/mars_factory-01.jpg"}}>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
      <div className='max-w-[1140px] m-auto p-4'>
        <h1 className='uppercase font-bold text-2xl text-center mb-4 text-[#6abf4b] mt-2'>{t('quality')}</h1>
        <p className='dark:text-white mb-3'>{t('qualityp1')}</p>
        <p className='dark:text-white mb-3'>{t('qualityp2')}</p>
        <p className='dark:text-white mb-3'>{t('qualityp3')}</p>
        <p className='dark:text-white mb-3'>{t('qualityp4')}</p>
        <p className='dark:text-white mb-3'>{t('qualityp5')}</p>
        <p className='dark:text-white'>{t('qualityp6')}</p>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
      <div className='max-w-[1140px] m-auto p-4 '>
        <h1 className='font-bold text-2xl text-center mb-4 text-[#6abf4b] mt-4'>{t('qmsp')}</h1>
        <p className='dark:text-white mb-3'>{t('qmspp1')}</p>
        <p className='dark:text-white mb-3'>{t('qmspp2')}</p>
        <p className='dark:text-white mb-3'>{t('qmspp3')}</p>
        <p className='dark:text-white mb-3'>{t('qmspp4')}</p>
        <p className='dark:text-white mb-3'>{t('qmspp5')}</p>
        <p className='dark:text-white'>{t('qmspp6')}</p>
      </div>
      <div className='h-24 w-px bg-lime-800 m-auto'></div>
    </div>
  )
}

export default Corporate