import React from 'react'
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaInstagram,
  FaLinkedinIn,
  FaFacebookF,
  FaWhatsapp,
} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="w-full m-auto bg-gray-200 dark:bg-gray-900 border-t border-lime-800">
        <div className="mx-auto w-full max-w-[1140px] p-4 py-6 lg:py-8">
            <div className="md:flex md:justify-between">
              <div className="mb-6 md:mb-0 md:m-0">
                  <a href="https://marselevator.it/">
                      <img src="/assets/img/marselevatorsrl-logo.png" className="h-10 mb-2 md:ml-0 m-auto drop-shadow-sm" alt="MarsElevator Logo"/>
                  </a>
                  <h2 className='md:text-left text-center dark:text-white'>MARS ELEVATOR SRL</h2>
              </div>
              <div>
                    <div className='sm:flex items-center md:justify-end'>
                      <FaMapMarkerAlt className='text-[#6abf4b] md:mr-2  m-auto'/>
                      <p className='md:text-right text-center dark:text-white'>Via Della Pace San Guiliano n.10C 20098 Milanese (MI)/ITALIA</p>
                    </div>
                    <div className='sm:flex items-center md:justify-end'>
                      <FaPhone className='text-[#6abf4b] md:mr-2 m-auto'/>
                      <p className='md:text-right text-center dark:text-white'>+39 028 082 90 75</p>
                    </div>
                    <div className='sm:flex items-center md:justify-end'>
                      <FaEnvelope className='text-[#6abf4b] md:mr-2 m-auto'/>
                      <p className='md:text-right text-center dark:text-white'>info.it@marselevator.com</p>
                    </div>
              </div>
          </div>
          <hr className="my-6 border-gray-400 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex items-center text-center sm:justify-between">
              <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://marselevator.it/" className="hover:underline">Marselevator™</a>. All Rights Reserved.
              </span>
              <div className="flex justify-center mt-4 sm:mt-0">
                <FaInstagram className='mr-4 text-gray-500 dark:text-white' />
                <FaLinkedinIn className='mr-4 text-gray-500 dark:text-white' />
                <FaFacebookF className='mr-4 text-gray-500 dark:text-white' />
                <FaWhatsapp className='text-gray-500 dark:text-white' />
              </div>
          </div>
        </div>
    </footer>
  )
}

export default Footer