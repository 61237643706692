import React from 'react';
import { useTranslation } from 'react-i18next';
import BrandRegistry from './documents/BrandRegistry';
import Certificate from './documents/Certificate';
import ProductsConformity from './documents/ProductsConformity';
import Catalog from './documents/Catalog';
import ManagementSystem from './documents/ManagementSystem';

const Documents = () => {
  const { t } = useTranslation();

  return (
    <>
    <div className='w-full mb-8'>
      <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
          <h1 className='mt-6 text-3xl text-[#6abf4b]'>DOCUMENTS</h1>
      </div>
    </div>
    <div className="max-w-[1140px] px-4 py-4 mx-auto dark:text-white">
      <Catalog />
    </div>
    <div className="max-w-[1140px] px-4 py-4 mx-auto dark:text-white">
      <Certificate />
    </div>
    <div className="max-w-[1140px] px-4 py-4 mx-auto dark:text-white">
      <ProductsConformity />
    </div>
    <div className="max-w-[1140px] px-4 py-4 mx-auto dark:text-white">
      <BrandRegistry />
    </div>
    <div className="max-w-[1140px] px-4 py-4 mx-auto dark:text-white">
      <ManagementSystem />
    </div>
  </>
  )
}

export default Documents