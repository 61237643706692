import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  return (
    <>
    <div className='w-full'>
      <div className='h-56 bg-repeat grid place-items-center border-b border-lime-800' style={{backgroundImage: "url(/assets/img/pattern_01.jpg"}}>
          <h1 className='uppercase mt-6 text-3xl text-[#6abf4b]'>{t('services')}</h1>
      </div>
    </div>
        <div className='h-24 w-px bg-lime-800 m-auto'></div>
        <div className='max-w-[1140px] m-auto py-4 px-4 dark:text-white'>
            <div className='text-center p-5'>
            <img
                src="/assets/img/assembly.svg"
                alt="assembly"
                width={50}
                className="object-center m-auto"
                />
                <h2 className='uppercase text-xl mb-2'>{t('assembly')}</h2>
                <p className='text-justify'>{t('assemblyp1')}</p>
            </div>
            <div className='text-center p-5'>
            <img
                src="/assets/img/renewal.svg"
                alt="assembly"
                width={50}
                className="object-center m-auto"
                />
                <h2 className='uppercase text-xl mb-2'>{t('modernization')}</h2>
                <p className='text-justify'>{t('modernizationp1')}</p>
            </div>
        </div>
        <div className='h-24 w-px bg-lime-800 m-auto'></div>
    </>
  )
}

export default Services