import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Corporate from "./pages/Corporate";
import Products from "./pages/Products";
import PassengerLiftM6100 from "./pages/products/PassengerLiftM6100";
import PassengerLiftM6200 from "./pages/products/PassengerLiftM6200";
import StretcherLiftA2100 from "./pages/products/StretcherLiftA2100";
import FreightLiftA2200 from "./pages/products/FreightLiftA2200";
import EmergencyLiftA2300 from "./pages/products/EmergencyLiftA2300";
import AntivandalA2400 from "./pages/products/AntivandalA2400";
import HomeliftR7150 from "./pages/products/HomeliftR7150";
import ModernizationR7350 from "./pages/products/ModernizationR7350";
import DumbwaiterS7100 from "./pages/products/DumbwaiterS7100";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Documents from "./pages/Documents";
import Career from "./pages/Career";
import Contact from "./pages/Contact";

const App = () => {
  return (
    <>
      <Suspense fallback={null}>
          <Routes>
            <Route exact path='/' element={<Homepage />}/>
            <Route path='/corporate' element={<Corporate />}/>
            <Route path='/products' element={<Products />}/>
            <Route path='/products/passengerliftm6100' element={<PassengerLiftM6100 />}/>
            <Route path='/products/passengerliftm6200' element={<PassengerLiftM6200 />}/>
            <Route path='/products/stretcherlifta2100' element={<StretcherLiftA2100 />}/>
            <Route path='/products/freightlifta2200' element={<FreightLiftA2200 />}/>
            <Route path='/products/emergencylifta2300' element={<EmergencyLiftA2300 />}/>
            <Route path='/products/antivandala2400' element={<AntivandalA2400 />}/>
            <Route path='/products/homeliftr7150' element={<HomeliftR7150 />}/>
            <Route path='/products/modernizationr7350' element={<ModernizationR7350 />}/>
            <Route path='/products/dumbwaiters7100' element={<DumbwaiterS7100 />}/>
            <Route path='/services' element={<Services />}/>
            <Route path='/projects' element={<Projects />}/>
            <Route path="/documents" element={<Documents />}/>
            <Route path="/career" element={<Career />}/>
            <Route path="/contact" element={<Contact />}/>
          </Routes>
      </Suspense>
    </>
  );
}

export default App;
