import React from 'react'
import { 
    MdGroups,
    MdSpeed,
    MdExpand
 } from "react-icons/md";
 import { useTranslation } from 'react-i18next';
 import { Link } from 'react-router-dom';

const ProductList = () => {
    const { t } = useTranslation();
  return (
    <div className='max-w-[1140px] w-full m-auto flex flex-wrap justify-between'>

        <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-m6100.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('passengerlift')}</h1>
                </div>
                <div  className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('m6100p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">300 ~ 1275 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">0,50 ~ 1,60 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">60 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/PassengerLiftM6100'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-m6200.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('passengerlift')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('m6200p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">300 ~ 1275 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">0,50 ~ 2,50 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">120 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/PassengerLiftM6200'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2100.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('stretcherlift')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2100p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">2.500 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">2,50 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">60 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/StretcherLiftA2100'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2200.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('freightlift')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2200p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">3.500 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">1,60 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">60 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/FreightLiftA2200'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2300.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('emergencylift')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2300p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">1.275 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">2,50 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">120 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/EmergencyLiftA2300'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-a2400.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('antivandal')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('a2400p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">1.275 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">2,50 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">120 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/AntivandalA2400'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-r7150.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('homelift')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-4">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('r7150p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">450 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">0,50 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">15 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/HomeliftR7150'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="max-w-[250px] bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden m-4">
                <img className="w-full h-56 object-cover object-center" src="/assets/img/productbox-r7350.jpg" alt="avatar"/>
                <div className="flex items-center px-6 py-3 bg-gray-900">
                    <h1 className="uppercase text-white font-semibold text-lg">{t('modernization')}</h1>
                </div>
                <div className="grid grid-row-2 place-content-between h-80 py-4 px-6">
                    <div>
                        <p className="py-2 text-md text-gray-700 dark:text-white">{t('r7350p1')}</p>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdGroups size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">450 kg</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdSpeed size={25} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">1,00 m/sn</h1>
                        </div>
                        <div className="flex items-center text-gray-700 dark:text-white">
                            <MdExpand size={30} className='text-[#6abf4b]'/>
                            <h1 className="px-2 text-sm">20 m.</h1>
                        </div>
                    </div>
                    <div className='mt-4 text-center'>
                        <Link to='../products/ModernizationR7350'>
                            <button className="bg-[#6abf4b] hover:bg-gray-700 text-white py-2 px-12 rounded uppercase">{t('readmore')}</button>
                        </Link>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default ProductList