import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './components/themeContext';
import TopBar from './components/TopBar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import "./i18n"
import ItalianFlag from './components/ItalianFlag';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <div className='bg-slate-50 dark:bg-zinc-900 transition-all'>
        <main>
          <TopBar />
          <Navbar />
          <App />
          <Footer />
          <ItalianFlag />
        </main>
      </div>
    </ThemeProvider>
  </BrowserRouter>
);