import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaWeightHanging,
  FaTachometerAlt,
  FaUserFriends,
  FaRulerVertical,
  FaBuffer,
  FaCheck
} from "react-icons/fa";

const EmergencyLiftA2300 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full h-[90vh]">
        <img
          src="/assets/img/a2300-image1.jpg"
          alt="M6200"
          className="w-full h-full object-cover"
        />
        <div className="max-w-[1140px] m-auto">
          <div className="absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4">
            <h2 className="text-3xl drop-shadow-sm">A2300</h2>
            <h1 className="uppercase text-4xl sm:text-6xl text-[#6abf4b] mb-4">
              {t("emergencylift")}
            </h1>
            <p className="text-4xl drop-shadow-sm">{t("a2300slogan")}</p>
          </div>
        </div>
      </div>

      <div className="max-w-[1140px] mx-auto my-4 p-4 dark:text-white">
        <div>
          <h2 className="text-center text-xl">A2300</h2>
          <h1 className="uppercase text-center text-4xl mb-8 text-[#6abf4b]">
            {t("emergencylift")}
          </h1>
        </div>
        <div className="mx-auto mb-8">
          <p1 className="text-4xl">{t("a2300p1")}</p1>
          <p className="mt-4">{t("a2300p2")}</p>
        </div>
        <div className="mx-auto">
          <p className="mt-4">{t("a2300p3")}</p>
        </div>
        <div className="mx-auto">
          <h2 className="uppercase text-xl my-4">{t("a2300kf")}</h2>
          <p className='flex items-center'><FaCheck className='mr-2 text-[#6abf4b]'/> {t('a2300kf1')}</p>
          <p className='flex items-center'><FaCheck className='mr-2 text-[#6abf4b]'/> {t('a2300kf2')}</p>
          <p className='flex items-center'><FaCheck className='mr-2 text-[#6abf4b]'/> {t('a2300kf3')}</p>
          <p className='flex items-center'><FaCheck className='mr-2 text-[#6abf4b]'/> {t('a2300kf4')}</p>
        </div>
      </div>

      <div className="h-24 w-px bg-lime-800 m-auto"></div>
      
      <div className="max-w-[1140px] px-4 py-10 mx-auto dark:text-white">
        <div className="flex flex-col text-center w-full mb-8">
          <h1 className="uppercase sm:text-2xl text-2xl font-medium title-font mb-4">
            {t("techspec")}
          </h1>
        </div>
        <div className="flex flex-wrap -m-4 text-center">
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaWeightHanging className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">
                750~2.500 kg
              </h2>
              <p className="leading-relaxed">{t("capacity")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaUserFriends className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">4~17</h2>
              <p className="leading-relaxed">{t("nop")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaTachometerAlt className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">
                0,5~2,50 m/sn
              </h2>
              <p className="leading-relaxed">{t("speed")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaRulerVertical className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">60 m</h2>
              <p className="leading-relaxed">{t("mtd")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaBuffer className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">16</h2>
              <p className="leading-relaxed">{t("msq")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M7,48.48V3.54c0-1.11,0.9-2.02,2.02-2.02h31.97c1.11,0,2.02,0.9,2.02,2.02v44.94"
                ></path>
                <path
                  class="st1"
                  d="M21.82,23.43V20.4c0-0.99-1.07-1.61-1.93-1.11l-9.96,5.75c-0.86,0.49-0.86,1.73,0,2.22l9.96,5.75
      c0.86,0.49,1.93-0.12,1.93-1.11v-5.75h6.36c0,0,0-5.75,0-5.75c0-0.99,1.07-1.61,1.93-1.11l9.96,5.75c0.86,0.49,0.86,1.73,0,2.22
      l-9.96,5.75c-0.86,0.49-1.93-0.12-1.93-1.11v-3.03"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                700~3500mm
              </h2>
              <p className="leading-relaxed">{t("doorwidth")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M7,48.48V3.54c0-1.11,0.9-2.02,2.02-2.02h31.97c1.11,0,2.02,0.9,2.02,2.02v44.94"
                ></path>
                <path
                  class="st1"
                  d="M22.23,31.83H19.2c-0.99,0-1.61,1.07-1.11,1.93l5.75,9.96c0.49,0.86,1.73,0.86,2.22,0l5.75-9.96
		c0.49-0.86-0.12-1.93-1.11-1.93h-5.75V20.47c0,0-5.75,0-5.75,0c-0.99,0-1.61-1.07-1.11-1.93l5.75-9.96c0.49-0.86,1.73-0.86,2.22,0
		l5.75,9.96c0.49,0.86-0.12,1.93-1.11,1.93h-3.03"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                2000~3500mm
              </h2>
              <p className="leading-relaxed">{t("doorheight")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M22.71,30.83h-2.56c-0.84,0-1.36,0.9-0.94,1.63l4.85,8.4c0.41,0.73,1.46,0.73,1.87,0l4.85-8.4	c0.41-0.73-0.1-1.63-0.94-1.63H25V18.46h-4.85c-0.84,0-1.36-0.9-0.94-1.63l4.85-8.4c0.41-0.73,1.46-0.73,1.87,0l4.85,8.4 c0.41,0.73-0.1,1.63-0.94,1.63H27.3"
                ></path>
                <path
                  class="st0"
                  d="M9.89,4.49l5.28,5.43v30.16L7,48.48c0,0,0-44.94,0-44.94c0-1.11,0.9-2.02,2.02-2.02h31.97	c1.11,0,2.02,0.9,2.02,2.02v44.94c0,0-8.18-8.4-8.18-8.4V9.92l5.28-5.43"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                2000~3800mm
              </h2>
              <p className="leading-relaxed">{t("cabinheight")}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="h-[500px] bg-no-repeat bg-cover bg-center bg-fixed border-y border-lime-800"
        style={{ backgroundImage: "url(/assets/img/a2300-image2.jpg" }}
      ></div>

      <div className="max-w-[1140px] mx-auto my-10 px-4 dark:text-white">
        <div className="md:flex m-auto">
          <div className="px-4">
            <h2 className="text-center text-xl mb-4">{t('a2300t1')}</h2>
            <img
              src="/assets/img/a2300-detail01.jpg"
              alt="A2300 Detail 01"
              className="mb-8"
            />
          </div>
          <div className="px-4">
            <h2 className="text-center text-xl mb-4">{t('a2300t2')}</h2>
            <img
              src="/assets/img/a2300-detail02.jpg"
              alt="A2300 Detail 02"
              className="mb-8"
            />
          </div>
          <div className="px-4">
            <h2 className="text-center text-xl mb-4">{t('a2300t3')}</h2>
            <img
              src="/assets/img/a2300-detail03.jpg"
              alt="A2300 Detail 03"
              className="mb-8"
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default EmergencyLiftA2300