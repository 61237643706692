import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaWeightHanging,
  FaTachometerAlt,
  FaUserFriends,
  FaRulerVertical,
  FaBuffer,
} from "react-icons/fa";

const FreightLiftA2200 = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full h-[90vh]">
        <img
          src="/assets/img/a2200-image1.jpg"
          alt="A2200"
          className="w-full h-full object-cover"
        />
        <div className="max-w-[1140px] m-auto">
          <div className="absolute top-[40%] w-full md:-[50%] max-w-[600px] flex flex-col text-white p-4">
            <h2 className="text-3xl drop-shadow-sm">A2200</h2>
            <h1 className="uppercase text-4xl drop-shadow-sm sm:text-6xl text-[#6abf4b] mb-4">
              {t("freightlift")}
            </h1>
            <p className="text-4xl drop-shadow-sm">{t("a2200slogan")}</p>
          </div>
        </div>
      </div>
      <div className="max-w-[1140px] mx-auto my-10 p-4 dark:text-white">
        <div>
          <h2 className="text-center text-xl">A2200</h2>
          <h1 className="uppercase text-center text-4xl mb-8 text-[#6abf4b]">
            {t("freightlift")}
          </h1>
        </div>
        <div className="mx-auto mb-8">
          <p1 className="text-4xl">{t("a2200p1")}</p1>
        </div>
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <h2 className="uppercase text-xl text-center mb-4">
              {t("a2200t1")}
            </h2>
            <p className="text-justify">{t("a2200p2")}</p>
          </div>
          <div>
            <h2 className="uppercase text-xl text-center mb-4">
              {t("a2200t2")}
            </h2>
            <p className="text-justify dark:text-white mb-4">{t("a2200p3")}</p>
          </div>
        </div>
      </div>

      <div className='max-w-[1140px] m-auto dark:text-white p-4'>
        <div className='relative overflow-x-auto'>
          <table className='w-full border-separate border-collapse border border-slate-400 text-center'>
            <thead>
              <tr>
                <th className='border border-slate-300 p-4'>{t('capacity')} (kg)</th>
                <th className='border border-slate-300 p-4'>{t('cwidth')} (mm)</th>
                <th className='border border-slate-300 p-4'>{t('cdepth')} (mm)</th>
                <th className='border border-slate-300 p-4'>{t('iwidth')} (mm)</th>
                <th className='border border-slate-300 p-4'>{t('iheight')} (mm)</th>
                <th className='border border-slate-300 p-4'>{t('lequiment')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='border border-slate-300 p-2'>750</td>
                <td className='border border-slate-300 p-2'>1.300</td>
                <td className='border border-slate-300 p-2'>2.300</td>
                <td className='border border-slate-300 p-2'>1.100</td>
                <td className='border border-slate-300 p-2'>2.100</td>
                <td className='border border-slate-300 p-2'></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>1.000</td>
                <td className='border border-slate-300 p-2'>1.700</td>
                <td className='border border-slate-300 p-2'>2.300</td>
                <td className='border border-slate-300 p-2'>1.400</td>
                <td className='border border-slate-300 p-2'>2.100</td>
                <td className='border border-slate-300 p-2 flex flex-wrap justify-center'>
                  <img
                    src='/assets/img/a2200-icon1.svg'
                    alt='icon'
                    className='w-12'
                    />
                  <img
                    src='/assets/img/a2200-icon2.svg'
                    alt='icon'
                    className='w-12'
                    />
                </td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>1.500</td>
                <td className='border border-slate-300 p-2'>2.000</td>
                <td className='border border-slate-300 p-2'>2.400</td>
                <td className='border border-slate-300 p-2'>1.700</td>
                <td className='border border-slate-300 p-2'>2.100</td>
                <td className='border border-slate-300 p-2'></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>2.000</td>
                <td className='border border-slate-300 p-2'>2.200</td>
                <td className='border border-slate-300 p-2'>2.800</td>
                <td className='border border-slate-300 p-2'>1.700</td>
                <td className='border border-slate-300 p-2'>2.100</td>
                <td className='border border-slate-300 p-2 flex flex-wrap justify-center'>                
                  <img
                    src='/assets/img/a2200-icon3.svg'
                    alt='icon'
                    className='w-12'
                    />
                  <img
                    src='/assets/img/a2200-icon4.svg'
                    alt='icon'
                    className='w-12'
                    />
                </td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'>3.000</td>
                <td className='border border-slate-300 p-2'>2.300</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>3.000</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'>3.400</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'></td>
              </tr>
              <tr>
                <td className='border border-slate-300 p-2'>3.500</td>
                <td className='border border-slate-300 p-2'>2.800</td>
                <td className='border border-slate-300 p-2'>3.800</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2'>2.500</td>
                <td className='border border-slate-300 p-2 flex flex-wrap justify-center'>
                  <img
                    src='/assets/img/a2200-icon5.svg'
                    alt='icon'
                    className='w-12'
                    />
                  <img
                    src='/assets/img/a2200-icon6.svg'
                    alt='icon'
                    className='w-12'
                    />
                  <img
                    src='/assets/img/a2200-icon7.svg'
                    alt='icon'
                    className='w-12'
                    />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="h-24 w-px bg-lime-800 m-auto"></div>
      <div className="max-w-[1140px] px-4 py-10 mx-auto dark:text-white">
        <div className="flex flex-col text-center w-full mb-8">
          <h1 className="uppercase sm:text-2xl text-2xl font-medium title-font mb-4">
            {t("techspec")}
          </h1>
        </div>
        <div className="flex flex-wrap -m-4 text-center">
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaWeightHanging className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">
                750~3.500 kg
              </h2>
              <p className="leading-relaxed">{t("capacity")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaUserFriends className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">10~47</h2>
              <p className="leading-relaxed">{t("nop")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaTachometerAlt className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">
                0,5~1,60 m/sn
              </h2>
              <p className="leading-relaxed">{t("speed")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaRulerVertical className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">40 m</h2>
              <p className="leading-relaxed">{t("mtd")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <FaBuffer className="w-10 h-10 mb-3 inline-block text-[#6abf4b]" />
              <h2 className="font-[Antonio] font-medium text-2xl">100</h2>
              <p className="leading-relaxed">{t("msq")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M7,48.48V3.54c0-1.11,0.9-2.02,2.02-2.02h31.97c1.11,0,2.02,0.9,2.02,2.02v44.94"
                ></path>
                <path
                  class="st1"
                  d="M21.82,23.43V20.4c0-0.99-1.07-1.61-1.93-1.11l-9.96,5.75c-0.86,0.49-0.86,1.73,0,2.22l9.96,5.75
      c0.86,0.49,1.93-0.12,1.93-1.11v-5.75h6.36c0,0,0-5.75,0-5.75c0-0.99,1.07-1.61,1.93-1.11l9.96,5.75c0.86,0.49,0.86,1.73,0,2.22
      l-9.96,5.75c-0.86,0.49-1.93-0.12-1.93-1.11v-3.03"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                700~2.600mm
              </h2>
              <p className="leading-relaxed">{t("doorwidth")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M7,48.48V3.54c0-1.11,0.9-2.02,2.02-2.02h31.97c1.11,0,2.02,0.9,2.02,2.02v44.94"
                ></path>
                <path
                  class="st1"
                  d="M22.23,31.83H19.2c-0.99,0-1.61,1.07-1.11,1.93l5.75,9.96c0.49,0.86,1.73,0.86,2.22,0l5.75-9.96
		c0.49-0.86-0.12-1.93-1.11-1.93h-5.75V20.47c0,0-5.75,0-5.75,0c-0.99,0-1.61-1.07-1.11-1.93l5.75-9.96c0.49-0.86,1.73-0.86,2.22,0
		l5.75,9.96c0.49,0.86-0.12,1.93-1.11,1.93h-3.03"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                2000~2300mm
              </h2>
              <p className="leading-relaxed">{t("doorheight")}</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 dark:border-gray-500 px-4 py-6 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                class="text-[#6abf4b] w-10 h-10 mb-3 inline-block"
                viewBox="0 0 48 48"
              >
                <path
                  class="st0"
                  d="M22.71,30.83h-2.56c-0.84,0-1.36,0.9-0.94,1.63l4.85,8.4c0.41,0.73,1.46,0.73,1.87,0l4.85-8.4	c0.41-0.73-0.1-1.63-0.94-1.63H25V18.46h-4.85c-0.84,0-1.36-0.9-0.94-1.63l4.85-8.4c0.41-0.73,1.46-0.73,1.87,0l4.85,8.4 c0.41,0.73-0.1,1.63-0.94,1.63H27.3"
                ></path>
                <path
                  class="st0"
                  d="M9.89,4.49l5.28,5.43v30.16L7,48.48c0,0,0-44.94,0-44.94c0-1.11,0.9-2.02,2.02-2.02h31.97	c1.11,0,2.02,0.9,2.02,2.02v44.94c0,0-8.18-8.4-8.18-8.4V9.92l5.28-5.43"
                ></path>
              </svg>
              <h2 className="font-[Antonio] font-medium text-2xl">
                2000~3000mm
              </h2>
              <p className="leading-relaxed">{t("cabinheight")}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-[500px] bg-no-repeat bg-cover bg-center bg-fixed border-y border-lime-800"
        style={{ backgroundImage: "url(/assets/img/a2200-image2.jpg" }}
      ></div>
      <div className="max-w-[1140px] mx-auto my-10 px-2 dark:text-white">
        <div className="grid md:grid-cols-2 content-start">
          <div className="row-span-3 mx-auto">
            <h2 className="uppercase text-center text-xl mb-4">{t('cabin')}</h2>
            <img
              src="/assets/img/a2200-cabin.jpg"
              alt="A2100 Cabin"
              className="mb-8"
            />
          </div>

          <div className="mx-auto mb-4 max-w-[400px]">
            <h2 className="uppercase text-center text-xl mb-4">{t('coating')}</h2>
            <div className="flex text-center">
              <div className='px-4'>
                <div className='w-[100px] h-[100px] mb-2 bg-[#b5b0a1]'></div>
                <p className="mb-4">RAL7032</p>
              </div>
              <div className='px-4'>
                <div className='w-[100px] h-[100px] mb-2 bg-[#a1a1a0]'></div>
                <p className="mb-4">RAL7032</p>
              </div>
              <div className='px-4'>
                <div className='w-[100px] h-[100px] mb-2 bg-[#ECECE8]'></div>
                <p className="mb-4">RAL9003</p>
              </div>
            </div>
            <div className="flex text-center">
              <div className='px-4'>
                <img
                  src="/assets/img/hairline-stainlesssteel.jpg"
                  alt="Hairline Stainless Steel"
                  className="max-w-[100px] mb-2"
                />
                <p className="mb-4">{t('hairline')}</p>
              </div>
              <div className='px-4'>
                <img
                  src="/assets/img/linen-stainlesssteel.jpg"
                  alt="Linen Stainless Steel"
                  className="max-w-[100px] mb-2"
                />
                <p className="mb-4">{t('linen')}</p>
              </div>
              <div className='px-4'>
                <img
                  src="/assets/img/leather-stainlesssteel.jpg"
                  alt="Leather Stainless Steel"
                  className="max-w-[100px] mb-2"
                />
                <p className="mb-4">{t('leather')}</p>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <h2 className="uppercase text-center text-xl mb-4">{t('floor')}</h2>
            <div className="flex text-center mb-8">
              <div className="max-w-[100px] mx-2">
                <img
                  src="/assets/img/aluminium-floor.jpg"
                  alt="Aluminium"
                  className="mb-2"
                />
                <p>{t('aluminium')}</p>
              </div>
              <div className="max-w-[100px] mx-2">
                <img
                  src="/assets/img/pvcblack-floor.jpg"
                  alt="PVC Black"
                  className="mb-2"
                />
                <p>{t('pvcblack')}</p>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <h2 className="text-center text-xl mb-4">LIGHTING</h2>
            <div className="flex text-center">
              <div className="max-w-[100px] mx-2">
                <img
                  src="/assets/img/lighting-ledbulb.jpg"
                  alt="LED Bulb"
                  className="mb-2"
                />
                <p>{t('ledbulb')}</p>
              </div>
              <div className="max-w-[100px] mx-2">
                <img
                  src="/assets/img/lighting-ledpanel.jpg"
                  alt="LED Panel"
                  className="mb-2"
                />
                <p>{t('ledpanel')}</p>
              </div>
              <div className="max-w-[100px] mx-2">
                <img
                  src="/assets/img/lighting-ledspot.jpg"
                  alt="LED Spot"
                  className="mb-2"
                />
                <p>{t('ledspot')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreightLiftA2200